import React, { lazy, Suspense, memo } from "react";
import { useLocalStorage } from "helpers/custom-hooks";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { 
  APP_PREFIX_PATH,
  APP_MAIN_PREFIX_PATH,
  APP_SYSTEM_PREFIX_PATH,
  APP_ENTRY_ROUTE
} from 'configs/AppConfig';
import { AUTH_TOKEN  } from "redux/constants/Auth";


export const AppViews = () => {

  const [token] = useLocalStorage(AUTH_TOKEN, false);

  if (!token) {
    return <Redirect to={APP_ENTRY_ROUTE} />;
  }

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_MAIN_PREFIX_PATH}/spy`} component={lazy(() => import(`./spy-game`))} />
        <Route path={`${APP_MAIN_PREFIX_PATH}/tod`} component={lazy(() => import(`./tod-game`))} />
        <Route path={`${APP_MAIN_PREFIX_PATH}/never`} component={lazy(() => import(`./never-game`))} />
        <Route path={`${APP_MAIN_PREFIX_PATH}/croc`} component={lazy(() => import(`./croc-game`))} />
        <Route path={`${APP_MAIN_PREFIX_PATH}/alias`} component={lazy(() => import(`./alias-game`))} />
        <Route path={`${APP_MAIN_PREFIX_PATH}/access`} component={lazy(() => import(`./access`))} />
        <Route path={`${APP_SYSTEM_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_MAIN_PREFIX_PATH}/access`} />
      </Switch>
    </Suspense>
  )
}

export default memo(AppViews);