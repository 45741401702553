import fetch from "auth/ClientFetchInterceptor";

const Auth = {};

Auth.login = (data) => {
  return fetch({
    method: "post",
    url: `auth/login/`,
    data
  });
};

Auth.logout = () => {
  return fetch({
    method: "get",
    url: `auth/logout/`
  });
};

export default Auth;