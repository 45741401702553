import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH, APP_ENTRY_ROUTE } from 'configs/AppConfig'

export const AppViews = () => {

  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route
          path={APP_ENTRY_ROUTE}
          component={lazy(() => import(`./login`))}
        />
        <Redirect
          from={AUTH_PREFIX_PATH}
          to={APP_ENTRY_ROUTE}
        />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

