import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Router } from 'react-router-dom';
import history from './history';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { DARK_MODE_KEY } from './configs/AppConfig';
import { useLocalStorage } from 'helpers/custom-hooks';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {

  const [isDarkMode] = useLocalStorage(DARK_MODE_KEY, false);
  
  const currentTheme = isDarkMode ? 'dark' : 'light';

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={currentTheme} insertionPoint="styles-insertion-point">
          <Router history={history}>
            <Switch>
              <Route path="/" component={Views}/>
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
