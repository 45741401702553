import {
  SettingOutlined,
} from '@ant-design/icons';
import {
  SpyIcon, 
  TodIcon,
  CrocIcon,
  NeverIcon,
  AliasIcon,
  AccessIcon
} from 'views/other/menu-icons';
import { APP_MAIN_PREFIX_PATH, APP_SYSTEM_PREFIX_PATH } from 'configs/AppConfig'

const mainNavTree = [
  {
    key: 'main',
    path: `${APP_MAIN_PREFIX_PATH}`,
    title: 'main',
    breadcrumb: false,
    submenu: [
      {
        key: 'spy',
        path: `${APP_MAIN_PREFIX_PATH}/spy`,
        title: 'spy',
        icon: SpyIcon,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'tod',
        path: `${APP_MAIN_PREFIX_PATH}/tod`,
        title: 'tod',
        icon: TodIcon,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'never',
        path: `${APP_MAIN_PREFIX_PATH}/never`,
        title: 'never',
        icon: NeverIcon,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'croc',
        path: `${APP_MAIN_PREFIX_PATH}/croc`,
        title: 'croc',
        icon: CrocIcon,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'alias',
        path: `${APP_MAIN_PREFIX_PATH}/alias`,
        title: 'alias',
        icon: AliasIcon,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'access',
        path: `${APP_MAIN_PREFIX_PATH}/access`,
        title: 'access',
        icon: AccessIcon,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
];

const systemNavTree = [
  {
    key: "system",
    path: `${APP_SYSTEM_PREFIX_PATH}`,
    title: "system",
    breadcrumb: false,
    submenu: [
      {
        key: 'settings',
        path: `${APP_SYSTEM_PREFIX_PATH}/settings`,
        title: 'settings',
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
];


const navigationConfig = [
  ...mainNavTree,
  ...systemNavTree
];

export default navigationConfig;
