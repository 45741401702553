import antdRuRU from 'antd/es/locale/ru_RU';
import ruMsg from "../locales/ru_RU.json";

const ruLang = {
  antd: antdRuRU,
  locale: 'en-US',
  messages: {
    ...ruMsg
  },
};
export default ruLang;