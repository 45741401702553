import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'HAMMER SYSTEMS - Games';
export const API_BASE_URL = env.API_ENDPOINT_URL

export const AUTH_PREFIX_PATH = '/auth';
export const APP_ENTRY_ROUTE = `${AUTH_PREFIX_PATH}/login`

export const APP_PREFIX_PATH = '/app';
export const APP_MAIN_PREFIX_PATH = `${APP_PREFIX_PATH}/main`;
export const APP_SYSTEM_PREFIX_PATH = `${APP_PREFIX_PATH}/system`;

export const DARK_MODE_KEY = "hs_games_dark_mode";

export const FULL_DATA_REQUEST = "FULL";

export const RECORDS_PER_PAGE = 10;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'ru',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false
};

export const COLOR_THEMES = [
  { id: "dark", title: "Темная" },
  { id: "light", title: "Светлая" },
];

export const FORM_MESSAGES = {
  success: "Успешно",
  loading: "Сохранение данных",
  error: "Ошибка",
};