import { message, notification } from "antd";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { reloadToEntryRoute } from "../helpers/native-functions";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config

const TOKEN_PAYLOAD_KEY = "Authorization";

service.interceptors.request.use(
  (config) => {

    const token = JSON.parse(localStorage.getItem(AUTH_TOKEN));
  
    // methods other than "get" (and "get"-logout) require token according to current api settings
    if (token && (config.url === "auth/logout/" || config.method !== "get")) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Token ${token}`;
    }
    
    // if (!token && config.url !== "/auth/login/") {
    //   reloadToEntryRoute();
    // }

    return config;

  },
  (error) => {
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error)

    if (error.response === undefined) {
      message.error("Ошибка сети");
      return;
    }

    if (error.response.status === 401) {
      const notificationParam = {};

      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      notification.error(notificationParam);

      localStorage.removeItem(AUTH_TOKEN);
      reloadToEntryRoute();
    }

    if (
      error.response.status === 400 ||
      error.response.status === 403
    ) {

      const errData = error.response.data;
      console.log(errData)
      let errArray = [];

      for (let key in errData) {
        errArray.push(errData[key]);
      }

      errArray.forEach(err => message.error(err));
      
    }

    if (error.response.status === 404) {
      message.error("Не найдено");
    }

    if (error.response.status === 500) {
      message.error("Ошибка сервера");
    }

    if (error.response.status === 508) {
      message.error("Время на ответ сервера истекло");
    }

    return Promise.reject(error);
  }
);

export default service;
