import React from "react";
import Icon from '@ant-design/icons';
import { ReactComponent as Spy } from "assets/svg/menu-icons/spy.svg";
import { ReactComponent as Tod } from "assets/svg/menu-icons/tod.svg";
import { ReactComponent as Croc } from "assets/svg/menu-icons/croc.svg";
import { ReactComponent as Never } from "assets/svg/menu-icons/never.svg";
import { ReactComponent as Alias } from "assets/svg/menu-icons/alias.svg";
import { ReactComponent as Access } from "assets/svg/menu-icons/access.svg";

export const SpyIcon = props => <Icon component={Spy} {...props}/>;

export const TodIcon = props => <Icon component={Tod} {...props}/>;

export const CrocIcon = props => <Icon component={Croc} {...props}/>;

export const NeverIcon = props => <Icon component={Never} {...props}/>;

export const AliasIcon = props => <Icon component={Alias} {...props}/>;

export const AccessIcon = props => <Icon component={Access} {...props}/>;
