import React from "react";
import { useDispatch } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { ReactComponent as Exit } from "assets/svg/other/logout.svg";

const Logout = () => {

  const dispatch = useDispatch();

  const logoutClick = () => {
    console.log("signout")
    dispatch(signOut());
  }

  return (
    <div className="Logout">
      {/* <div
        
      > */}
        <Exit className="Logout-Icon" onClick={logoutClick}/>
      {/* </div> */}
    </div>
  );
};

export default Logout;